import React from "react";

import Icon from "../../Icon/Icon";
import ModalBackdrop from "../../ModalBackdrop/ModalBackdrop";
import Slider from "../../Slider/Slider";
import {TCaseImages} from "../../Case/types";
import {IProps as ModalProps, IState as ModalState} from "./types";

export default class ImageModal extends ModalBackdrop<ModalProps, ModalState> {
  state: ModalState = {};

  renderImages = (data: TCaseImages) => {
    return data.map((item, i) => {
      return <img key={i} alt={item.alt} className="imageModal-image" src={item.src} />;
    });
  };

  renderModal = () => {
    const {data} = this.props;

    return (
      <div className="imageModal">
        <Slider childSelectors=".imageModal-image" childVisibleClassName="imageModal-image--visible">
          {this.renderImages(data)}
        </Slider>
        <span className="imageModal-close" onClick={() => this.hide()}>
          <Icon name="x" title="Закрыть" />
        </span>
      </div>
    );
  };
}
