import React from "react";
import {Link} from "react-router-dom";

import Clients from "../../components/Clients/Clients";
import ContactsSection from "../../components/ContactsSection/ContactsSection";
import Icon from "../../components/Icon/Icon";
import HowWorkSection from "../../components/HowWorkSection/HowWorkSection";
import HomeSection from "../../components/HomeSection/HomeSection";
import PageSection from "../../components/PageSection";
import Promo from "../../components/Promo";
import Review from "../../components/Review";
import Slider from "../../components/Slider/Slider";
import Seo from "../../components/Seo/Seo";
import YaMap from "../../components/YaMap";
import {META, H1, LINKS, REVIEWS_INDEXES} from "./variables";
import {REVIEWS} from "../../../variables/REVIEWS";

export default class Main extends React.Component<{}, {}> {
  renderLinks = () => {
    return LINKS.map((item, i) => {
      return (
        <div key={i} className={`main-link main-link--${item.link}`}>
          <div className="main-linkContainer">
            <div className="main-linkIcon">
              <img src={item.icon} alt={item.iconAlt} />
            </div>
            <div className="main-linkTitle">{item.title}</div>
            <div className="main-linkDescription">{item.description}</div>
            <Link to={`/${item.link}/`}>
              <span>Подробнее</span>
              <Icon name="arrowDown" title="Подробнее о мебели" />
            </Link>
          </div>
        </div>
      );
    });
  };

  renderReviews = () => {
    let arr = REVIEWS.filter((item, i) => REVIEWS_INDEXES.includes(i));

    return arr.map((item, i) => {
      return <Review key={i} author={item.author} text={item.text} service={item.service} />;
    });
  };

  render() {
    return (
      <div className="page main">
        <Seo title={META.title} description={META.description} canonical="" />

        <HomeSection
          h1={H1}
          beforeChildren={
            <>
              <span>Возможность беспроцентной рассрочки</span>
              <span>Производство полного цикла на современном оборудовании в цеху площадью более 1000 кв м.</span>
              <span>Есть вопросы? Оставьте номер телефона и мы проконсультируем вас</span>
            </>
          }
        />
        {/*<Promo />*/}
        <PageSection title="Наши клиенты">
          <Clients />
        </PageSection>
        <PageSection title="Изготавливаем мебель">
          <div className="main-links">{this.renderLinks()}</div>
          <div className="main-etc">
            А так же: мебель для детской комнаты ● мебель в спальню ● офисная мебель ● гардеробные ● прихожие
          </div>
        </PageSection>
        <PageSection title="Как мы работаем">
          <HowWorkSection />
        </PageSection>
        <PageSection title="Отзывы">
          <div className="page-reviewsM">
            <Slider childSelectors=".review" childVisibleClassName="review--visible">
              {this.renderReviews()}
            </Slider>
          </div>
          <div className="page-reviews">{this.renderReviews()}</div>
        </PageSection>
        <PageSection title="Контакты">
          <ContactsSection items={["address", "time", "tel"]} />
          <YaMap />
        </PageSection>
      </div>
    );
  }
}
