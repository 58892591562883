import {TCase} from "../../components/Case/types";
import {TBlock, TForm} from "../../components/CalculationSection/types";
import kitchen_1_1 from "../../../assets/images/cases/kitchen-1-1.png";
import kitchen_1_2 from "../../../assets/images/cases/kitchen-1-2.png";
import kitchen_1_3 from "../../../assets/images/cases/kitchen-1-3.png";
import kitchen_2_1 from "../../../assets/images/cases/kitchen-2-1.png";
import kitchen_2_2 from "../../../assets/images/cases/kitchen-2-2.png";
import kitchen_2_3 from "../../../assets/images/cases/kitchen-2-3.png";
import kitchen_3_1 from "../../../assets/images/cases/kitchen-3-1.png";
import kitchen_3_2 from "../../../assets/images/cases/kitchen-3-2.png";
import kitchen_3_3 from "../../../assets/images/cases/kitchen-3-3.png";
import kitchen_4_1 from "../../../assets/images/cases/kitchen-4-1.png";
import kitchen_4_2 from "../../../assets/images/cases/kitchen-4-2.png";
import kitchen_4_3 from "../../../assets/images/cases/kitchen-4-3.png";
import kitchen_4_4 from "../../../assets/images/cases/kitchen-4-4.png";

export const META: {[index: string]: string} = {
  title: "Кухни на заказ в Омске по индивидуальным размерам",
  description:
    "Ваша кухня почти готова, осталось лишь сделать замеры и выбрать фурнитуру. Опытный дизайнер поможет сделать выбор в соответствии с вашими пожеланиями учитывая эргономику кухонного гарнитура. Собственное производство фасадов позволяет нам сделать вашу будущую кухню максимально доступной для вас."
};

export const H1: string = "Кухни на заказ в Омске";

export const REVIEWS_INDEXES: Array<number> = [0, 1, 2];

export const FAQ_INDEXES: Array<number> = [0, 2, 3, 4, 5];

export const CASES: Array<TCase> = [
  {
    images: [
      {src: kitchen_1_1, alt: "Кухня 1-1"},
      {src: kitchen_1_2, alt: "Кухня 1-2"},
      {src: kitchen_1_3, alt: "Кухня 1-3"}
    ],
    title: "П-образная кухня",
    description:
      "<b>Длинна:</b> 4.9м<br /><b>Столешница:</b> 38мм влагостойкая<br /><b>Фасады:</b> мдф в пленке пвх, глянцевые<br /><b>Другое:</b> встроенная вытяжка, мебельные ручки GOLA, антресоль",
    price: "196 000"
  },
  {
    images: [
      {src: kitchen_2_1, alt: "Кухня 2-1"},
      {src: kitchen_2_2, alt: "Кухня 2-2"},
      {src: kitchen_2_3, alt: "Кухня 2-3"}
    ],
    title: "Угловая белая кухня",
    description:
      "<b>Длинна:</b> 4м<br /><b>Столешница:</b> каменная<br /><b>Фасады:</b> мдф в пленке пвх, глянцевые<br /><b>Другое:</b> встроенная вытяжка, каменная мойка, мебельные ручки GOLA, антресоль, подсветка рабочей области",
    price: "160 000"
  },
  {
    images: [
      {src: kitchen_3_1, alt: "Кухня 3-1"},
      {src: kitchen_3_2, alt: "Кухня 3-2"},
      {src: kitchen_3_3, alt: "Кухня 3-3"}
    ],
    title: "Угловая кухня с нишей",
    description:
      "<b>Длинна:</b> 4.2м<br /><b>Столешница:</b> каменная<br /><b>Фасады:</b> мдф в пленке пвх, глянцевые и матовые<br /><b>Другое:</b> встроенная вытяжка, каменная мойка, мебельные ручки GOLA, антресоль, подсветка рабочей области",
    price: "168 000"
  },
  {
    images: [
      {src: kitchen_4_1, alt: "Кухня 4-1"},
      {src: kitchen_4_2, alt: "Кухня 4-2"},
      {src: kitchen_4_3, alt: "Кухня 4-3"},
      {src: kitchen_4_4, alt: "Кухня 4-4"}
    ],
    title: "Светлая угловая кухня",
    description:
      "<b>Длинна:</b> 5м<br /><b>Столешница:</b> каменная<br /><b>Фасады:</b> мдф в пленке пвх, матовые<br /><b>Другое:</b> высокие верхние шкафы, каменная мойка, мебельные ручки GOLA, подсветка рабочей области",
    price: "200 000"
  }
];

export const calculationStr = (obj: TForm) => {
  const {countertop, doorsMaterials, shape, length} = obj;
  let s = `${shape.charAt(0).toUpperCase()}${shape.slice(1)}`;
  let c = `Столешница: ${countertop}`;
  let dM = `Фасады: ${doorsMaterials}`;
  let l = `Длинна: ${length} м`;
  let f = "Просчитать кухню";

  return `%3Cb%3E${f}%3C%2Fb%3E%0A${s}. ${c}. ${dM}. ${l}.`;
};

export const CALCULATION_CONFIG: TBlock[] = [
  {
    title: "Выберите форму кухонного гарнитура",
    valueContainer: "string",
    disabledCondition: "shape",
    items: [
      {type: "checkButton", value: "прямая", valueName: "shape", text: "Прямая"},
      {type: "checkButton", value: "угловая", valueName: "shape", text: "Угловая"},
      {type: "checkButton", value: "п-образная", valueName: "shape", text: "П-образная"},
      {type: "checkButton", value: "с островом", valueName: "shape", text: "С островом"}
    ]
  },
  {
    title: "Выберите столешницу",
    valueContainer: "string",
    disabledCondition: "countertop",
    items: [
      {type: "checkButton", value: "искусственный камень", valueName: "countertop", text: "Искусственный камень"},
      {
        type: "checkButton",
        value: "38мм влагостойкая покрытая пластиком",
        valueName: "countertop",
        text: "38мм влагостойкая покрытая пластиком"
      },
      {
        type: "checkButton",
        value: "26 мм не влагостойкая покрытая пластиком",
        valueName: "countertop",
        text: "26 мм не влагостойкая покрытая пластиком"
      }
    ]
  },
  {
    title: "Выберите фасады",
    valueContainer: "string",
    disabledCondition: "doorsMaterials",
    items: [
      {
        type: "checkButton",
        value: "мдф в пвх пленке (без фрезеровки)",
        valueName: "doorsMaterials",
        text: "МДФ в ПВХ пленке (без фрезеровки)"
      },
      {
        type: "checkButton",
        value: "мдф в пвх пленке (с фрезеровкой)",
        valueName: "doorsMaterials",
        text: "МДФ в ПВХ пленке (с фрезеровкой)"
      },
      {type: "checkButton", value: "массив дерева", valueName: "doorsMaterials", text: "Массив дерева"},
      {type: "checkButton", value: "мдф акриловые (эмаль)", valueName: "doorsMaterials", text: "МДФ акриловые (эмаль)"},
      {
        type: "checkButton",
        value: "высокоглянцевые панели",
        valueName: "doorsMaterials",
        text: "Высокоглянцевые панели"
      }
    ]
  },
  {
    title: "Какая длинна в метрах",
    valueContainer: "string",
    disabledCondition: "length",
    items: [{type: "textField", valueName: "length", text: "Длинна, м"}]
  },
  {
    title: "Укажите номер телефона",
    valueContainer: "string",
    items: [{type: "textField", valueName: "tel", text: "Номер телефона"}]
  },
  {
    title: "Спасибо за оставленную заявку на расчет",
    items: [
      {
        type: "text",
        text: "Расчет цены будет готов в самое ближайшее время. Мы сообщим стоимость вашего будущего кухонного гарнитура по указанному номеру телефона."
      }
    ]
  }
];
