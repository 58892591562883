import React from "react";
import {Link} from "react-router-dom";

import Icon from "../../../components/Icon/Icon";
import {LINKS} from "../variables";
import {CONTACTS} from "../../../../variables/CONTACTS";
import Logo from "../../../../assets/images/logo.png";

export default class HeaderMenu extends React.Component<{currentPathname: string}, {isVisible: boolean}> {
  constructor(props: {currentPathname: string}) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  close = () => {
    this.setState({isVisible: false}, () => (document.body.style.overflow = "auto"));
  };

  show = () => {
    this.setState({isVisible: true}, () => (document.body.style.overflow = "hidden"));
  };

  renderLinks = () => {
    return LINKS.map((item, i) => {
      return (
        <li className="headerMenu-link" key={i}>
          {this.props.currentPathname === item.url ? (
            <span>{item.text}</span>
          ) : (
            <Link to={item.url} onClick={() => this.close()}>
              {item.text}
            </Link>
          )}
        </li>
      );
    });
  };

  render() {
    return (
      <div className={`headerMenu ${this.state.isVisible ? "headerMenu--visible" : ""}`}>
        <div className="headerMenu-header">
          <img alt="Логотип" className="headerMenu-headerLogo" src={Logo} />
          <span className="headerMenu-close" onClick={() => this.close()}>
            <Icon name="x" title="Закрыть меню" />
          </span>
        </div>

        <div className="headerMenu-contacts">
          <Icon name="location" title="Адрес" />
          {CONTACTS.address}
        </div>
        <div className="headerMenu-contacts">
          <Icon name="clock" title="Время работы" />
          {`${CONTACTS.week} ${CONTACTS.time}`}
        </div>
        <div className="headerMenu-contacts">
          <Icon name="phone" title="Телефон" />
          <a href={`tel:+${CONTACTS.phone.replace(/\D/g, "")}`}>{CONTACTS.phone}</a>
        </div>
        <ul>{this.renderLinks()}</ul>
      </div>
    );
  }
}
