import React from "react";
import {Link} from "react-router-dom";

import Seo from "../components/Seo/Seo";

export default class NotFound extends React.Component<{}, {}> {
  render() {
    return (
      <div className="page notFound">
        <Seo title="Ошибка 404" description="" />

        <div className="notFound-title">404</div>
        <div className="notFound-description">Упс... Что-то пошло не так. Такой страницы не существует.</div>
        <Link to="/">Вернуться на главную страницу</Link>
      </div>
    );
  }
}
