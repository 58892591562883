import React from "react";

import {ITEMS} from "./variables";

export default class Clients extends React.Component<{}, {}> {
  renderItems = () => {
    return ITEMS.map((item, i) => {
      return (
        <div key={i} className="clients-item">
          <img src={item.img} alt={item.alt} />
        </div>
      );
    });
  };

  render() {
    return <div className="clients">{this.renderItems()}</div>;
  }
}
