import {TCase} from "../../components/Case/types";
import {TBlock, TForm} from "../../components/CalculationSection/types";
import closet_1_1 from "../../../assets/images/cases/closet-1-1.png";
import closet_1_2 from "../../../assets/images/cases/closet-1-2.png";
import closet_1_3 from "../../../assets/images/cases/closet-1-3.png";
import closet_1_4 from "../../../assets/images/cases/closet-1-4.png";
import closet_2_1 from "../../../assets/images/cases/closet-2-1.png";
import closet_2_2 from "../../../assets/images/cases/closet-2-2.png";
import closet_3_1 from "../../../assets/images/cases/closet-3-1.png";
import closet_3_2 from "../../../assets/images/cases/closet-3-2.png";
import closet_3_3 from "../../../assets/images/cases/closet-3-3.png";
import closet_3_4 from "../../../assets/images/cases/closet-3-4.png";

export const META: {[index: string]: string} = {
  title: "Шкаф-купе в Омске недорого на заказ по индивидуальным размерам",
  description:
    "Ваш шкаф-купе почти готов, осталось лишь сделать замеры и выбрать наполнение. Опытный дизайнер покажет, расскажет и поможет сделать выбор комбинаций дверей шкафа-купе: зеркало, крашенное стекло, лакобель, пескоструй, лдсп, мдф."
};

export const H1: string = "Шкафы-купе на заказ в Омске";

export const REVIEWS_INDEXES: Array<number> = [0, 1, 2];

export const FAQ_INDEXES: Array<number> = [1, 2, 4, 6];

export const CASES: Array<TCase> = [
  {
    images: [
      {src: closet_1_1, alt: "Шкаф-купе 1-1"},
      {src: closet_1_2, alt: "Шкаф-купе 1-2"},
      {src: closet_1_3, alt: "Шкаф-купе 1-3"},
      {src: closet_1_4, alt: "Шкаф-купе 1-4"}
    ],
    title: "Трехдверный корпусный шкаф-купе в детскую комнату",
    description:
      "<b>Ширина:</b> 2.2м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое серебро<br /><b>Двери:</b> лдсп двери с разделителем, зеркальная дверь с пескоструйным рисунком<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "54999"
  },
  {
    images: [
      {src: closet_2_1, alt: "Шкаф-купе 2-1"},
      {src: closet_2_2, alt: "Шкаф-купе 2-2"}
    ],
    title: "Двухдверный встроенный шкаф-купе с зеркальными дверьми",
    description:
      "<b>Ширина:</b> 1.5м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовая шампань<br /><b>Двери:</b> зеркальные двери с пескоструйным рисунком<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "37499"
  },
  {
    images: [
      {src: closet_3_1, alt: "Шкаф-купе 3-1"},
      {src: closet_3_2, alt: "Шкаф-купе 3-2"},
      {src: closet_3_3, alt: "Шкаф-купе 3-3"},
      {src: closet_3_4, alt: "Шкаф-купе 3-4"}
    ],
    title: "Двухдверный встроенный шкаф-купе в прихожей",
    description:
      "<b>Ширина:</b> 1.4м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое золото<br /><b>Двери:</b> лдсп дверь, зеркальная дверь с пескоструйным рисунком по периметру<br /><b>Наполнение:</b> выдвижная штанга для вешалок, полки и ящики из лдсп",
    price: "34999"
  }
];

export const calculationStr = (obj: TForm) => {
  const {doors, doorsMaterials, innerMaterials, shape, type, width, height, depth} = obj;
  let t = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
  let d = `дверей ${doors}`;
  let dM = `Материал дверей: ${doorsMaterials.replace("+", "%2B")}`;
  let iM = `Внутреннее наполнение: ${innerMaterials.length ? innerMaterials.toString().replace(/,/g, ", ") : "не выбрано"}`;
  let dim = `Размеры в сантиметрах: ширина ${width}, высота ${height}, глубина ${depth}`;
  let f = "Просчитать шкаф-купе";

  return `%3Cb%3E${f}%3C%2Fb%3E%0A${t}, ${shape}, ${d}. ${dM}. ${iM}. ${dim}.`;
};

export const CALCULATION_CONFIG: TBlock[] = [
  {
    title: "Выберите тип",
    valueContainer: "string",
    disabledCondition: "type",
    items: [
      {type: "checkButton", value: "корпусный", valueName: "type", text: "Корпусный"},
      {type: "checkButton", value: "встроенный", valueName: "type", text: "Встроенный"}
    ]
  },
  {
    title: "Выберите форму",
    valueContainer: "string",
    disabledCondition: "shape",
    items: [
      {type: "checkButton", value: "прямой", valueName: "shape", text: "Прямой"},
      {type: "checkButton", value: "угловой", valueName: "shape", text: "Угловой"}
    ]
  },
  {
    title: "Выберите количество дверей",
    valueContainer: "number",
    disabledCondition: "doors",
    items: [
      {type: "checkButton", value: 2, valueName: "doors", text: "2 двери"},
      {type: "checkButton", value: 3, valueName: "doors", text: "3 двери"},
      {type: "checkButton", value: 4, valueName: "doors", text: "4 двери"},
      {type: "checkButton", value: 5, valueName: "doors", text: "5 дверей"}
    ]
  },
  {
    title: "Выберите наполнение дверей",
    valueContainer: "string",
    disabledCondition: "doorsMaterials",
    items: [
      {type: "checkButton", value: "лдсп", valueName: "doorsMaterials", text: "ЛДСП"},
      {type: "checkButton", value: "зеркало", valueName: "doorsMaterials", text: "Зеркало"},
      {type: "checkButton", value: "окрашенное стекло", valueName: "doorsMaterials", text: "Окрашенное стекло"},
      {type: "checkButton", value: "лдсп + зеркало", valueName: "doorsMaterials", text: "ЛДСП + Зеркало"},
      {
        type: "checkButton",
        value: "лдсп + окрашенное стекло",
        valueName: "doorsMaterials",
        text: "ЛДСП + Окрашенное стекло"
      },
      {
        type: "checkButton",
        value: "окрашенное стекло + зеркало",
        valueName: "doorsMaterials",
        text: "Окрашенное стекло + Зеркало"
      }
    ]
  },
  {
    title: "Выберите внутреннее наполнение",
    valueContainer: "array",
    disabledCondition: null,
    items: [
      {type: "checkButton", value: "полки", valueName: "innerMaterials", text: "Полки"},
      {type: "checkButton", value: "выдвижные ящики", valueName: "innerMaterials", text: "Выдвижные ящики"},
      {type: "checkButton", value: "штанги для вешалок", valueName: "innerMaterials", text: "Штанги для вешалок"},
      {
        type: "checkButton",
        value: "выдвижные сетчатые корзины",
        valueName: "innerMaterials",
        text: "Выдвижные сетчатые корзины"
      }
    ]
  },
  {
    title: "Укажите размеры в сантиметрах",
    valueContainer: "string",
    disabledCondition: ["width", "height", "depth"],
    items: [
      {type: "textField", valueName: "width", text: "Ширина, см"},
      {type: "textField", valueName: "height", text: "Высота, см"},
      {type: "textField", valueName: "depth", text: "Глубина, см"}
    ]
  },
  {
    title: "Укажите номер телефона",
    valueContainer: "string",
    items: [{type: "textField", valueName: "tel", text: "Номер телефона"}]
  },
  {
    title: "Спасибо за оставленную заявку на расчет",
    items: [
      {
        type: "text",
        text: "Расчет цены будет готов в самое ближайшее время. Мы сообщим стоимость вашего будущего шкафа-купе по указанному номеру телефона."
      }
    ]
  }
];
