import React, {Component} from "react";

import icons from "./icons";

export default class Icon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      angleDown: icons.angleDown,
      arrowDown: icons.arrowDown,
      at: icons.at,
      clock: icons.clock,
      list: icons.list,
      location: icons.location,
      phone: icons.phone,
      starFill: icons.starFill,
      starHalf: icons.starHalf,
      x: icons.x
    };
  }

  getIcon = () => {
    const parts = this.props.name.split(".");
    let result = this.state;
    let part;
    while ((part = parts.shift())) {
      result = result[part];
    }
    return result;
  };

  render() {
    const {name, title} = this.props;
    const icon = this.getIcon();

    if (!icon) {
      throw new Error(`Invalid icon: ${name}`);
    }

    return (
      <span className={`icon ${icon.name}`} title={title}>
        <svg viewBox={icon.viewBox} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          {icon.d.map((path, index) => {
            return <path key={`${name}.${index}`} fillRule="evenodd" d={path} />;
          })}
        </svg>
      </span>
    );
  }
}
