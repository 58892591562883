import kitchen_1_1 from "../../../assets/images/3dprojects/kitchen-1-1.png";
import {TCaseImages} from "../Case/types";

export const ITEMS: {title: string; description: string}[] = [
  {
    title: "Выезд дизайнера",
    description:
      "Дизайнер подъедет к вам в удобное для вас время. Сделает предварительные замеры, покажет образцы комплектующих и фурнитуры, ответит на все интересующие вас вопросы и запишет все пожелания."
  },
  {
    title: "Утверждение дизайн-проекта",
    description:
      "В удобное для вас время дизайнер покажет готовый дизайн-проект и внесет правки если таковые требуются."
  },
  {
    title: "Предоплата",
    description: "Заключение договора и предоплата 70% от стоимости мебели."
  },
  {
    title: "Выезд технолога",
    description: "Технолог сделает контрольные замеры и зафиксирует технические нюансы."
  },
  {
    title: "Изготовление мебели",
    description: ""
  },
  {
    title: "Установка мебели",
    description: "По завершению изготовления мебели созвонимся с вами и назначим удобную для вас дату установки."
  }
];

export const PROJECTS: TCaseImages = [{src: kitchen_1_1, alt: "Дизайн-проект кухни"}];
