import React from "react";
import Icon from "./Icon/Icon";

export default class Spoiler extends React.Component<{description: string; title: string}, {isVisible: boolean}> {
  constructor(props: {description: string; title: string}) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  toggle = (value: boolean) => {
    this.setState({isVisible: !value});
  };

  render() {
    const {description, title} = this.props;
    const {isVisible} = this.state;

    return (
      <div className="spoiler">
        <div
          className={`spoiler-title ${isVisible ? "spoiler-title--visible" : ""}`}
          onClick={() => this.toggle(isVisible)}
        >
          <h3>{title}</h3>
          <Icon name="angleDown" title="Показать/Скрыть" />
        </div>
        <div className={`spoiler-description ${isVisible ? "spoiler-description--visible" : ""}`}>{description}</div>
      </div>
    );
  }
}
