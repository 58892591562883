import React from "react";

import {TProps} from "./types";

export default class TextField extends React.Component<TProps, {}> {
  render() {
    const {label, onChange, value} = this.props;

    return (
      <div className="textField">
        <div className="textField-label">{label}</div>
        <input type="text" value={value} onChange={(e) => onChange(e.target.value)} />
      </div>
    );
  }
}
