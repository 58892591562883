import React from "react";

import ContactsSection from "../../components/ContactsSection/ContactsSection";
import PageSection from "../../components/PageSection";
import Seo from "../../components/Seo/Seo";
import YaMap from "../../components/YaMap";
import {META} from "./variables";

export default class Contacts extends React.Component<{canonical: string}, {}> {
  render() {
    return (
      <div className="page contacts">
        <Seo title={META.title} description={META.description} canonical={this.props.canonical} />

        <PageSection title="Контактная информация">
          <ContactsSection />
          <YaMap />
        </PageSection>
      </div>
    );
  }
}
