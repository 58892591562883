import React from "react";
import {Outlet} from "react-router-dom";

export default class Content extends React.Component<{}, {}> {
  render() {
    return (
      <div className="content">
        <Outlet />
      </div>
    );
  }
}
