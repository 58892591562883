import React from "react";

import TextField from "../../TextField/TextField";
import ModalBackdrop from "../../ModalBackdrop/ModalBackdrop";
import Icon from "../../Icon/Icon";
import {sendMessage} from "../../../../usecases/telegram";
import {TProps as ModalProps, TState as ModalState} from "./types";
import {initialState} from "./variables";

export default class CallModal extends ModalBackdrop<ModalProps, ModalState> {
  state: ModalState = initialState();

  hide = () => {
    this.setState(initialState(), () => super.hide());
  };

  submit = async () => {
    try {
      let tel = this.state.form.tel.replace("+", "%2B");
      let response = await sendMessage(tel, this.state.form.name);

      if (response.ok) {
        this.setState({isSubmitted: true});
      }
    } catch (e) {
      this.setState({isError: true});
    }
  };

  onChange = (field: string, value: string) => {
    this.setState((prevState) => {
      prevState.form[field] = value;
      return prevState;
    });
  };

  renderPending = () => {
    const {isError} = this.state;
    const {name, tel} = this.state.form;

    return (
      <>
        <div className="callModal-header">
          <div className="callModal-headerTitle">Заказать обратный звонок</div>
          <div className="callModal-headerDescription">
            Оставьте свой номер телефона и мы свяжемся с вами в ближайшее время
          </div>
        </div>
        <TextField label="Имя" onChange={(value) => this.onChange("name", value)} value={name} />
        <TextField label="Номер телефона" onChange={(value) => this.onChange("tel", value)} value={tel} />
        {isError ? <div className="callModal-error">Ошибка. Что-то пошло не так. Попробуйте позже.</div> : null}
        <button
          className={`button button-fill ${tel === "+7" ? "button--disabled" : ""}`}
          onClick={() => (tel === "+7" ? null : this.submit())}
          disabled={tel === "+7"}
        >
          Отправить
        </button>
      </>
    );
  };

  renderSubmitted = () => {
    return (
      <>
        <div className="callModal-submitted">
          Благодарим за оставленную заявку. В самое ближайшее время мы свяжемся с вами.
        </div>
        <button className="button button-fill" onClick={() => this.hide()}>
          Закрыть
        </button>
      </>
    );
  };

  renderModal = () => {
    const {isSubmitted} = this.state;

    return (
      <div className="callModal">
        {isSubmitted ? this.renderSubmitted() : this.renderPending()}

        <span className="callModal-close" onClick={() => this.hide()}>
          <Icon name="x" title="Закрыть" />
        </span>
      </div>
    );
  };
}
