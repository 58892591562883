import React, {useRef} from "react";
import {useLocation} from "react-router";

import ClickAwayListener from "../../components/ClickAwayListener";
import HeaderDropdown from "./components/HeaderDropdown";
import HeaderMenu from "./components/HeaderMenu";
import Icon from "../../components/Icon/Icon";
import Logo from "../../../assets/images/logo.png";
import {Link} from "react-router-dom";

export const Header = () => {
  const menu = useRef<HeaderMenu>(null);
  const dropdown = useRef<HeaderDropdown>(null);
  const pathname = useLocation().pathname;

  return (
    <div className="header">
      <div className="header-container">
        <Link to="/">
          <img alt="Логотип" className="header-logo" src={Logo} />
        </Link>
        <div className="header-menuIcon" onClick={() => menu && menu.current && menu.current.show()}>
          <Icon name="list" title="Меню" />
        </div>
        <div className="header-dropdownWrap">
          <ClickAwayListener onClickAway={() => dropdown && dropdown.current && dropdown.current.close()}>
            <div className="header-dropdown" onClick={() => dropdown && dropdown.current && dropdown.current.show()}>
              <div className="header-dropdownText">Меню</div>
              <Icon name="list" title="Меню" />
              <HeaderDropdown ref={dropdown} currentPathname={pathname} />
            </div>
          </ClickAwayListener>
        </div>

        <HeaderMenu ref={menu} currentPathname={pathname} />
      </div>
    </div>
  );
};
