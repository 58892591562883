import {CONTACTS} from "../../../variables/CONTACTS";

export const ITEMS: {[index: string]: string}[] = [
  {
    name: "address",
    icon: "location",
    iconTitle: "Адрес",
    title: "Адрес",
    text: CONTACTS.address
  },
  {
    name: "time",
    icon: "clock",
    iconTitle: "Часы работы",
    title: "Часы работы",
    text: `${CONTACTS.week} ${CONTACTS.time}`
  },
  {
    name: "tel",
    icon: "phone",
    iconTitle: "Телефон",
    title: "Телефон",
    text: CONTACTS.phone
  },
  {
    name: "mail",
    icon: "at",
    iconTitle: "Электронная почта",
    title: "Эл. почта",
    text: CONTACTS.email
  }
];
