import React from "react";

import ImageModal from "../modals/ImageModal/ImageModal";
import {ITEMS, PROJECTS} from "./variables";

export default class HowWorkSection extends React.Component<{}, {}> {
  private modal: React.RefObject<ImageModal>;
  constructor(props: {}) {
    super(props);

    this.modal = React.createRef();
  }

  renderItems = () => {
    return ITEMS.map((item, i) => {
      return (
        <div key={i} className="howWorkSection-item">
          <div className="howWorkSection-itemCount">{i + 1}</div>
          <div className="howWorkSection-itemTitle">{item.title}</div>
          <div className="howWorkSection-itemDescription">{item.description}</div>
          {i === 1 ? (
            <div className="howWorkSection-itemSample" onClick={() => this.modal.current?.show()}>
              (Пример дизайн-проекта)
            </div>
          ) : null}
        </div>
      );
    });
  };

  render() {
    return (
      <>
        <div className="howWorkSection">{this.renderItems()}</div>

        <ImageModal ref={this.modal} data={PROJECTS} />
      </>
    );
  }
}
