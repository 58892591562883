import React from "react";
import {createPortal} from "react-dom";

import {IProps as MainProps, IState as MainState} from "./types";

export default class ModalBackdrop<Props extends MainProps, State extends MainState> extends React.Component<
  Props,
  State
> {
  State: MainState = {
    isVisible: false
  };

  private modalBackdrop: React.RefObject<HTMLDivElement>;
  public renderModal: () => JSX.Element;

  constructor(props: Props) {
    super(props);

    this.modalBackdrop = React.createRef();

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.renderModal = (): JSX.Element => {
      return <div />;
    };
  }

  show(): void {
    this.setState({isVisible: true}, () => {
      document.body.style.overflow = "hidden";
      if (this.modalBackdrop && this.modalBackdrop.current) {
        this.modalBackdrop.current.focus();
      }
    });
  }

  hide() {
    this.setState({isVisible: false}, () => (document.body.style.overflow = "auto"));
  }

  onKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (e.code === "Escape") {
      this.hide();
    }
  };

  render() {
    const {isVisible} = this.state;

    return (
      <>
        {createPortal(
          <div
            className={`modalBackdrop ${isVisible ? "modalBackdrop--visible" : ""}`}
            ref={this.modalBackdrop}
            onClick={(e) => (e.target === this.modalBackdrop.current ? this.hide() : null)}
            onKeyDown={this.onKeyDown}
            tabIndex={1}
          >
            {this.renderModal()}
          </div>,
          document.body
        )}
      </>
    );
  }
}
