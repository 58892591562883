import euromed from "../../../assets/images/clients/euromed.png";
import kdmhotel from "../../../assets/images/clients/kdmhotel.png";
import lensmaster from "../../../assets/images/clients/lensmaster.png";
import omskreaktiv from "../../../assets/images/clients/omskreaktiv.png";
import rzd from "../../../assets/images/clients/rzd.png";
import vtb from "../../../assets/images/clients/vtb.png";

export const ITEMS: {img: string; alt: string}[] = [
  {img: euromed, alt: "Евромед"},
  {img: kdmhotel, alt: "Гостиница «Молодежная»"},
  {img: lensmaster, alt: "Линзмастер"},
  {img: omskreaktiv, alt: "Омскреактив"},
  {img: rzd, alt: "РЖД"},
  {img: vtb, alt: "ВТБ"}
];
