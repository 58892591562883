import React from "react";
import {RouterProvider} from "react-router-dom";

import {ROUTER} from "./ROUTER";

const App = () => {
  return <RouterProvider router={ROUTER} />;
};

export default App;
