import React from "react";

import Case from "../../components/Case/Case";
import CalculationSection from "../../components/CalculationSection/CalculationSection";
import Clients from "../../components/Clients/Clients";
import ContactsSection from "../../components/ContactsSection/ContactsSection";
import FaqSection from "../../components/FaqSection";
import HomeSection from "../../components/HomeSection/HomeSection";
import HowWorkSection from "../../components/HowWorkSection/HowWorkSection";
import PageSection from "../../components/PageSection";
import Promo from "../../components/Promo";
import Review from "../../components/Review";
import Slider from "../../components/Slider/Slider";
import Seo from "../../components/Seo/Seo";
import YaMap from "../../components/YaMap";
import {REVIEWS} from "../../../variables/REVIEWS";
import {META, FAQ_INDEXES, REVIEWS_INDEXES, H1, CASES, calculationStr, CALCULATION_CONFIG} from "./variables";

export default class Kitchens extends React.Component<{canonical: string}, {}> {
  renderCases = () => {
    return CASES.map((item, i) => {
      return <Case key={i} images={item.images} title={item.title} description={item.description} price={item.price} />;
    });
  };

  renderReviews = () => {
    let arr = REVIEWS.filter((item, i) => REVIEWS_INDEXES.includes(i));

    return arr.map((item, i) => {
      return <Review key={i} author={item.author} text={item.text} service={item.service} />;
    });
  };

  render() {
    return (
      <div className="page kitchens">
        <Seo title={META.title} description={META.description} canonical={this.props.canonical} />

        <HomeSection
          h1={H1}
          beforeChildren={
            <>
              <span>Изготовление за 21 рабочий день</span>
              <span>Возможность беспроцентной рассрочки</span>
              <span>Производство полного цикла на современном оборудовании в цеху площадью более 1000 кв м.</span>
              <span>Есть вопросы? Оставьте номер телефона и мы проконсультируем вас</span>
            </>
          }
          afterChildren={
            <>
              <span>Хотите узнать стоимость?</span>
              <a href="#calculation">Рассчитать стоимость</a>
            </>
          }
        />
        {/*<Promo />*/}
        <PageSection title="Наши клиенты">
          <Clients />
        </PageSection>
        <PageSection title="Последние работы">
          <div className="page-cases">{this.renderCases()}</div>
        </PageSection>
        <span id="calculation" />
        <PageSection
          title="Хотите узнать стоимость будущей кухни?"
          description="Выберите нужные вам материалы, укажите размер и узнайте цену вашего кухонного гарнитура"
        >
          <CalculationSection config={CALCULATION_CONFIG} strFunc={(form) => calculationStr(form)} />
        </PageSection>
        <PageSection title="Как мы работаем">
          <HowWorkSection />
        </PageSection>
        <PageSection title="Часто задаваемые вопросы">
          <FaqSection indexes={FAQ_INDEXES} />
        </PageSection>
        <PageSection title="Отзывы">
          <div className="page-reviewsM">
            <Slider childSelectors=".review" childVisibleClassName="review--visible">
              {this.renderReviews()}
            </Slider>
          </div>
          <div className="page-reviews">{this.renderReviews()}</div>
        </PageSection>
        <PageSection title="Контакты">
          <ContactsSection items={["address", "time", "tel"]} />
          <YaMap />
        </PageSection>
      </div>
    );
  }
}
