import React from "react";

import CheckButton from "../CheckButton/CheckButton";
import TextField from "../TextField/TextField";
import {TProps, TState, TBlockItem} from "./types";
import {sendMessage} from "../../../usecases/telegram";

export default class CalculationSection extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      block: 0,
      form: {
        type: "",
        shape: "",
        doors: 0,
        countertop: "",
        doorsMaterials: "",
        innerMaterials: [],
        tel: "+7",
        width: "",
        height: "",
        depth: "",
        length: ""
      }
    };
  }

  submit = async () => {
    try {
      let response = await sendMessage(
        this.state.form.tel.replace("+", "%2B"),
        "",
        this.props.strFunc(this.state.form)
      );

      if (response.ok) {
        this.setValue("block", this.props.config.length - 1);
      }
    } catch (e) {
      // TODO
    }
  };

  setValue = (name: string, value: string | number) => {
    if (name === "form.innerMaterials") {
      this.setState((prevState) => {
        let result = [...prevState.form.innerMaterials];
        let val = value as string;
        let index = result.indexOf(val);
        if (result.includes(val)) {
          result.splice(index, 1);
        } else {
          result.push(val);
        }
        return {form: {...prevState.form, innerMaterials: [...result]}};
      });
    } else {
      this.setState((prevState) => {
        let parts = name.split(".");
        let result = {...prevState};
        while (parts.length > 1) {
          result = result[parts.shift() as string];
        }
        result[parts[0]] = value;
        if (name.split(".").length === 1) {
          prevState = {...result};
        }
        return prevState;
      });
    }
  };

  renderBlockItems = (items: TBlockItem[], valueContainer?: string) => {
    return items.map((item, i) => {
      if (item.type === "checkButton") {
        return (
          <CheckButton
            key={i}
            checked={
              valueContainer === "array"
                ? this.state.form[item.valueName!].includes(item.value)
                : this.state.form[item.valueName!] === item.value
            }
            text={item.text}
            onChange={() => this.setValue(`form.${item.valueName}`, item.value!)}
          />
        );
      } else if (item.type === "textField") {
        return (
          <TextField
            key={i}
            onChange={(value) => this.setValue(`form.${item.valueName}`, value)}
            value={this.state.form[item.valueName!]}
            label={item.text}
          />
        );
      } else if (item.type === "text") {
        return item.text;
      } else {
        return null;
      }
    });
  };

  isDisabled = (disabledCondition?: null | string | string[]) => {
    if (disabledCondition === null) {
      return false;
    } else if (typeof disabledCondition === "string") {
      return !this.state.form[disabledCondition];
    } else if (typeof disabledCondition === "object") {
      let arr = [];
      for (let i = 0; i < disabledCondition.length; i++) {
        arr.push(Boolean(this.state.form[disabledCondition[i]]));
      }
      return arr.includes(false);
    }
  };

  renderSecondButton = (current: number, isSubmit: boolean, disabledCondition?: null | string | string[]) => {
    if (isSubmit) {
      return (
        <button
          className={`button button-fill ${this.state.form.tel === "+7" ? "button--disabled" : ""}`}
          onClick={() => (this.state.form.tel === "+7" ? null : this.submit())}
          disabled={this.state.form.tel === "+7"}
        >
          Отправить
        </button>
      );
    } else {
      return (
        <button
          className={`button button-fill ${this.isDisabled(disabledCondition) ? "button--disabled" : ""}`}
          onClick={() => (this.isDisabled(disabledCondition) ? null : this.setValue("block", current + 1))}
          disabled={this.isDisabled(disabledCondition)}
        >
          Далее
        </button>
      );
    }
  };

  renderBlockNav = (current: number, length: number, disabledCondition?: null | string | string[]) => {
    if (current + 1 === length) {
      return null;
    } else if (current === 0) {
      return (
        <button
          className={`button button-fill ${this.isDisabled(disabledCondition) ? "button--disabled" : ""}`}
          onClick={() => (this.isDisabled(disabledCondition) ? null : this.setValue("block", current + 1))}
          disabled={this.isDisabled(disabledCondition)}
        >
          Далее
        </button>
      );
    } else {
      return (
        <>
          <button className="button button-fill" onClick={() => this.setValue("block", current - 1)}>
            Назад
          </button>
          {this.renderSecondButton(current, current === length - 2, disabledCondition)}
        </>
      );
    }
  };

  renderBlocks = () => {
    return this.props.config.map((block, i, arr) => {
      return (
        <div
          key={i}
          className={`calculationSection-block ${this.state.block === i ? "calculationSection-block--visible" : ""}`}
        >
          <h3>{block.title}</h3>
          <div className="calculationSection-blockItems">
            {this.renderBlockItems(block.items, block.valueContainer)}
          </div>
          <div className="calculationSection-nav">{this.renderBlockNav(i, arr.length, block.disabledCondition)}</div>
        </div>
      );
    });
  };

  render() {
    return <div className="calculationSection">{this.renderBlocks()}</div>;
  }
}
