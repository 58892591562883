import React from "react";

import Icon from "../Icon/Icon";
import {TProps} from "./types";

export default class Slider extends React.Component<TProps, {count: number; currentIndex: number}> {
  private containerRef: React.RefObject<HTMLDivElement>;
  constructor(props: TProps) {
    super(props);
    this.state = {
      count: 0,
      currentIndex: 0
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.setState({count: Array.isArray(this.props.children) ? this.props.children.length : 0}, () =>
      this.toggleClassNames(0)
    );
  }

  toggleClassNames = (index: number) => {
    let {childSelectors, childVisibleClassName} = this.props;

    let container = this.containerRef.current!;
    let slides = Array.from(container.querySelectorAll(childSelectors));
    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.remove(childVisibleClassName);
    }
    slides[index].classList.add(childVisibleClassName);
  };

  prev = () => {
    this.setState({currentIndex: this.state.currentIndex - 1}, () => this.toggleClassNames(this.state.currentIndex));
  };

  next = () => {
    this.setState({currentIndex: this.state.currentIndex + 1}, () => this.toggleClassNames(this.state.currentIndex));
  };

  render() {
    const {children} = this.props;
    const {count, currentIndex} = this.state;

    return (
      <div className="slider">
        {count ? (
          <>
            <button
              className={`slider-button slider-buttonPrev ${currentIndex ? "" : "slider-button--disabled"}`}
              onClick={() => (currentIndex ? this.prev() : null)}
            >
              <Icon name="angleDown" title="Назад" />
            </button>
            <button
              className={`slider-button slider-buttonNext ${currentIndex === count - 1 ? "slider-button--disabled" : ""}`}
              onClick={() => (currentIndex === count - 1 ? null : this.next())}
            >
              <Icon name="angleDown" title="Вперед" />
            </button>
          </>
        ) : null}
        <div className="slider-container" ref={this.containerRef}>
          {children}
        </div>
      </div>
    );
  }
}
