export const initialState = () => {
  return {
    form: {
      name: "",
      tel: "+7"
    },
    isSubmitted: false,
    isError: false
  };
};
