import React from "react";

import ImageModal from "../modals/ImageModal/ImageModal";
import {TCase} from "./types";

export default class Case extends React.Component<TCase, {}> {
  private modal: React.RefObject<ImageModal>;
  constructor(props: TCase) {
    super(props);

    this.modal = React.createRef();
  }

  render() {
    const {images, title, description, price} = this.props;

    return (
      <>
        <div className="case">
          <img
            className="case-image"
            src={images[0].src}
            alt={images[0].alt}
            onClick={() => this.modal.current?.show()}
          />
          <h3 className="case-title">{title}</h3>
          <div className="case-description" dangerouslySetInnerHTML={{__html: description}} />
          <div className="case-price">Цена: от {price} руб.</div>
        </div>
        <ImageModal ref={this.modal} data={images} />
      </>
    );
  }
}
