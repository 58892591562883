import React, {Component} from "react";

export default class ClickAwayListener extends Component {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.clickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.clickOutside);
  };

  clickOutside = (e) => {
    const {onClickAway} = this.props;

    if (this.wrapper && !this.wrapper.current.contains(e.target)) {
      onClickAway();
    }
  };

  render() {
    return <div ref={this.wrapper}>{this.props.children}</div>;
  }
}
