import twoGisLogo from "../assets/images/reviews/2GIS_logo.png";
import yandexLogo from "../assets/images/reviews/yandex_logo.png";
import flampLogo from "../assets/images/reviews/flamp_logo.png";

export const REVIEWS_SERVICES: {[index: string]: {logo: string; link: string; name: string; rating: string}} = {
  "2gis": {
    logo: twoGisLogo,
    link: "https://2gis.ru/omsk/firm/282003258402467?m=73.44411%2C54.996298%2F16",
    name: "2ГИС",
    rating: "4.2"
  },
  flamp: {
    logo: flampLogo,
    link: "https://omsk.flamp.ru/firm/pulsar_ooo_mebelnaya_fabrika-282003258402467",
    name: "Фламп",
    rating: "4.6"
  },
  yandex: {
    logo: yandexLogo,
    link: "https://yandex.ru/maps/org/pulsar/100051972207/reviews/?ll=73.444138%2C54.996309&z=16",
    name: "Яндекс",
    rating: "4.1"
  }
};
