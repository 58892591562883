import React from "react";

import {TProps} from "./types";

export default class CheckButton extends React.Component<TProps, {}> {
  onChange = () => {
    const {onChange} = this.props;
    onChange();
  };

  render() {
    const {checked, text} = this.props;

    return (
      <div className={`checkButton ${checked ? "checkButton--checked" : ""}`} onClick={() => this.onChange()}>
        {text}
      </div>
    );
  }
}
