import React from "react";
import {ScrollRestoration} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";

import {Header} from "./app/layouts/Header/Header";
import Content from "./app/layouts/Content";
import Footer from "./app/layouts/Footer";

export const AppLayout = () => (
  <HelmetProvider>
    <ScrollRestoration />
    <Header />
    <Content />
    <Footer />
  </HelmetProvider>
);
