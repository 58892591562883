export const REVIEWS: {author: string; text: string; service: string}[] = [
  {
    author: "staptsova.elena",
    text: "Доброго времени суток!!! Хочу поделиться своими впечатлениями о мебельной фабрике Пульсар - быстро, качественно и в срок!!! Рекомендую!!!",
    service: "flamp"
  },
  {
    author: "Yaroslav Daukenov",
    text: "За неделю выполнили заказ на шкаф, распилом. Хорошее качество, адекватные цены и быстро!<br />Спасибо! За вторым шкафом к вам)",
    service: "2gis"
  },
  {
    author: "Андрей V.",
    text: "Заказывали кухню. Всё сделали в срок, хорошего качества. Собрали тоже очень быстро. Врезали мойку и варочную поверхность. При проектировании встроенной микроволновки учли все требования по установке. Рекомендую.",
    service: "yandex"
  }
];
