import React from "react";
import ReactDOM from "react-dom/client";
import "modern-normalize";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import "./sass/index.scss";

const APP = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const ROOT_ELEMENT = document.getElementById("root") as HTMLElement;
const ROOT = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (ROOT_ELEMENT.hasChildNodes()) {
  ReactDOM.hydrateRoot(ROOT_ELEMENT, APP);
} else {
  ROOT.render(APP);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
