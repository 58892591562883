import React from "react";

import Bubbles from "../Bubbles";
import CallModal from "../modals/CallModal/CallModal";
import {TProps} from "./types";

export default class HomeSection extends React.Component<TProps, {}> {
  private modal: React.RefObject<CallModal>;
  constructor(props: TProps) {
    super(props);

    this.modal = React.createRef();
  }

  render() {
    const {h1, beforeChildren, afterChildren} = this.props;

    return (
      <div className="homeSection">
        <Bubbles />
        <div className="homeSection-header">
          <h1>{h1}</h1>

          <div className="homeSection-description">
            {beforeChildren ? <div className="homeSection-beforeChildren">{beforeChildren}</div> : null}
            <button className="button button-fill" onClick={() => this.modal.current?.show()}>
              Заказать звонок
            </button>
            {afterChildren ? <div className="homeSection-afterChildren">{afterChildren}</div> : null}
          </div>
        </div>
        <CallModal ref={this.modal} />
      </div>
    );
  }
}
