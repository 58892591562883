import React from "react";

export default class YaMap extends React.Component<{}, {}> {
  private containerRef: React.RefObject<HTMLDivElement>;
  constructor(props: {}) {
    super(props);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.createMap();
  }

  componentWillUnmount() {
    // Cleanup function for prevent firing twice componentDidMount in development
    // https://legacy.reactjs.org/docs/strict-mode.html#ensuring-reusable-state
    // https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    this.deleteMap();
  }

  createMap = () => {
    const script = document.createElement("script");
    this.containerRef.current?.appendChild(script);
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.src =
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A2U9MHRfc5roVGKk65NCWyK3taOUYrwD5&amp;width=100%&amp;height=100%&amp;lang=ru_RU&amp;scroll=false";
  };

  deleteMap = () => (this.containerRef.current ? (this.containerRef.current.innerHTML = "") : null);

  render() {
    return <div className="yaMap" ref={this.containerRef} />;
  }
}
