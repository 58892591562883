import icon_kitchen from "../../../assets/images/icons/kitchen.png";
import icon_closet from "../../../assets/images/icons/closet.png";

export const META: {[index: string]: string} = {
  title: "Корпусная мебель на заказ в Омске от производителя",
  description:
    "Изготовление корпусной мебели в кратчайшие сроки по низким ценам. Корпусная мебель любой сложности, от кухонных гарнитуров и шкафов-купе, до гардеробных комнат и торговой мебели. Богатый опыт работы в 20 лет и собственный цех."
};

export const H1: string = "Корпусная мебель на заказ в Омске";

export const LINKS: {[index: string]: string}[] = [
  {
    icon: icon_kitchen,
    iconAlt: "Кухни на заказ",
    title: "Кухни",
    description:
      "Работая с нами, вы можете быть уверены, что мы найдем лучшее и наиболее оригинальное решение именно для вас",
    link: "kuhni"
  },
  {
    icon: icon_closet,
    iconAlt: "Шкафы-купе на заказ",
    title: "Шкафы-купе",
    description: "Мы используем опыт нашей профессиональной команды в разработке каждого заказа",
    link: "shkafy-kupe"
  }
];

export const REVIEWS_INDEXES: Array<number> = [0, 1, 2];
