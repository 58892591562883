import React from "react";

export default class Bubbles extends React.Component<{}, {}> {
  render() {
    return (
      <div className="bubbles">
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
}
