import React from "react";
import {Link} from "react-router-dom";

import Icon from "../../../components/Icon/Icon";
import {CONTACTS} from "../../../../variables/CONTACTS";
import {LINKS} from "../variables";

export default class HeaderDropdown extends React.Component<{currentPathname: string}, {isVisible: boolean}> {
  constructor(props: {currentPathname: string}) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  close = () => {
    this.setState({isVisible: false});
  };

  show = () => {
    this.setState({isVisible: true});
  };

  renderLinks = () => {
    return LINKS.map((item, i) => {
      return (
        <li key={i}>
          {this.props.currentPathname === item.url ? (
            <span>{item.text}</span>
          ) : (
            <Link
              to={item.url}
              onClick={(e) => {
                e.stopPropagation();
                this.close();
              }}
            >
              {item.text}
            </Link>
          )}
        </li>
      );
    });
  };

  render() {
    return (
      <div className={`headerDropdown ${this.state.isVisible ? "headerDropdown--visible" : ""}`}>
        <div className="headerDropdown-contacts">
          <div className="headerDropdown-contactsItem">
            <Icon name="location" title="Адрес" />
            {CONTACTS.address}
          </div>
          <div className="headerDropdown-contactsItem">
            <Icon name="clock" title="Время работы" />
            {`${CONTACTS.week} ${CONTACTS.time}`}
          </div>
          <div className="headerDropdown-contactsItem">
            <Icon name="phone" title="Телефон" />
            <a href={`tel:+${CONTACTS.phone.replace(/\D/g, "")}`}>{CONTACTS.phone}</a>
          </div>
        </div>
        <ul className="headerDropdown-links">{this.renderLinks()}</ul>
      </div>
    );
  }
}
