import React from "react";

import faqImage from "../../assets/images/faq/faq.svg";
import Spoiler from "./Spoiler";
import TextField from "./TextField/TextField";
import {FAQ} from "../../variables/FAQ";
import {sendMessage} from "../../usecases/telegram";

export default class FaqSection extends React.Component<{indexes: number[]}, {tel: string; isSubmitted: boolean}> {
  constructor(props: {indexes: number[]}) {
    super(props);
    this.state = {
      tel: "+7",
      isSubmitted: false
    };
  }

  submit = async () => {
    try {
      let response = await sendMessage(this.state.tel.replace("+", "%2B"), "", "%3Cb%3EНужна консультация%3C%2Fb%3E");
      if (response.ok) {
        this.setState({isSubmitted: true});
      }
    } catch (e) {
      // TODO
    }
  };

  renderItems = () => {
    let arr = FAQ.filter((item, i) => this.props.indexes.includes(i));

    return arr.map((item, i) => {
      return <Spoiler key={i} description={item.answer} title={item.question} />;
    });
  };

  render() {
    const {tel, isSubmitted} = this.state;

    return (
      <div className="faqSection">
        <div className="faqSection-answers">
          <div className="faqSection-image">
            <img src={faqImage} alt="Часто задаваемые вопросы" />
          </div>
          <div className="faqSection-items">{this.renderItems()}</div>
        </div>
        <div className="faqSection-call">
          <div className="faqSection-callTitle">Нужна консультация по другому вопросу?</div>
          <div className="faqSection-callDescription">Оставьте номер телефона и мы свяжемся с вами</div>
          <div className="faqSection-callForm">
            {isSubmitted ? (
              <div className="faqSection-callForm--submitted">
                Спасибо за оставленную заявку. Перезвоним вам в самое ближайшее время и ответим на все интересующие вас
                вопросы.
              </div>
            ) : (
              <>
                <TextField onChange={(value) => this.setState({tel: value})} value={tel} />
                <button
                  className={`button button-fill ${tel !== "+7" ? "" : "button--disabled"}`}
                  disabled={tel === "+7"}
                  onClick={() => this.submit()}
                >
                  Связаться со мной
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
