import React from "react";

export default class PageSection extends React.Component<
  {children: React.ReactNode; description?: string; title: string},
  {}
> {
  render() {
    const {children, description, title} = this.props;

    return (
      <div className="pageSection">
        <div className="pageSection-header">
          <h2 className="pageSection-title">{title}</h2>
          {description ? <p className="pageSection-description">{description}</p> : null}
        </div>
        {children}
      </div>
    );
  }
}
